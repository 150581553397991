function scrollToInvalid(form) {
  var navbar = $('.nav-wrapper');

// listen for `invalid` events on all form inputs
  form.find(':input').on('invalid', function(event) {
    var input = $(this)
    // the first invalid element in the form
    var first = form.find('input:invalid').first()
    var label = $('label[for="' + $(this).attr('id') + '"]');
    input.addClass('error')
    label.addClass('error-label')
    // only handle if this is the first invalid input
    if (input[0] === first[0]) {
      // height of the nav bar plus some padding
      var navbarHeight = 200

      // the position to scroll to (accounting for the navbar)
      var elementOffset = input.offset().top - navbarHeight

      // the current scroll position (accounting for the navbar)
      var pageOffset = window.pageYOffset - navbarHeight

      // don't scroll if the element is already in view
      if (elementOffset > pageOffset && elementOffset < pageOffset + window.innerHeight) {
        return true
      }

      // note: avoid using animate, as it prevents the validation message displaying correctly
      $('html,body').scrollTop(elementOffset)
    }
  });
}

(function ($) {
  $(document).foundation();

  $(document).ready(function () {

    $('.front ul.menu li a[href*="#"]').on('click', function (e) {
      e.preventDefault();

      var target = this.hash;
      $target = $(target);

      $("ul.menu li a").removeClass("is-active");
      $(this).addClass("is-active");

      $(".off-canvas").removeClass("is-open");
      $(".off-canvas-content").removeClass("is-open-left");
      $(".js-off-canvas-overlay").removeClass("is-visible");

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top
      }, 900, 'swing', function () {
        window.location.hash = target;
      });

    });

    $('.js-add-price').click(function(e) {
      clickToAdd(e, this);
    });
    $('.js-add-stages').click(function(e) {
      clickToAdd(e, this);
    });
    $('.js-add-work').click(function(e) {
      clickToAdd(e, this);
    });

    function clickToAdd(e, d) {
      e.preventDefault();
      var list = $($(d).attr('data-list'));
      // Try to find the counter of the list
      var counter = list.data('widget-counter') | list.children().length;
      // If the counter does not exist, use the length of the list
      if (!counter) { counter = list.children().length; }

      // grab the prototype template
      var newWidget = list.attr('data-prototype');
      // replace the "__name__" used in the id and name of the prototype
      // with a number that's unique to your emails
      // end name attribute looks like name="contact[emails][2]"
      newWidget = newWidget.replace(/__name__/g, counter);
      // Increase the counter
      counter++;

      // And store it, the length cannot be used if deleting widgets is allowed
      list.data(' widget-counter', counter);

      // create a new list element and add it to the list
      var newElem = $(list.attr('data-widget-tags')).html(newWidget);
      newElem.appendTo(list);
    }
    var form = $('#contact-form')   //your form element
    scrollToInvalid(form);
  });
})(jQuery);



